.Legal {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    font-family: var(--textFont);
    color: var(--textColor);
    background: var(--backgroundColor);
    background-size: cover !important;
    width: 100%;
    min-height: 100%;
    background-position: center;
}

.App-header {
    margin-left: 3%;
}

.Legal-box {
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Legal h1{
    color: #202020;
}

.Legal .App-container {
    filter: blur(10px) opacity(40%);
    text-align: center;
}
.Legal .App-header {
    height: 5vh;
}

.legal-text {
    width: 100%;
    margin-bottom: 10px;
    text-align: justify;
    padding-right: 30px;
    margin-top: 30px;
}

.legal-question {
    text-align: justify;
    margin-top: 4vh;
}

.legal-question label{
    display:block;
    width: 100%
}

.legal-terminos-servicio input{
    margin-right: 1vh;
}

.button-legal{
    margin-top: 20px;
}

.TituloCondicions{
    text-align: center;
}

.TituloCondicions h1{
    margin-top: 0;
}

.Description {
    width: 60%;
}

@media only screen and (max-width: 460px) {
    .legal {
        height: unset !important;
    }
    .TituloCondicions h1{
        padding-top: 10px;
    }
    .Description {
        width: 90%;
    }
    .legal-question {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        flex-direction: column;
        margin-bottom: 40px;
    }

}