.Alert {
  z-index: 30;
  position: absolute;
  top: 200px;
  right: 0;
}

.Alert .notification {
  padding-top: 8px;
  margin-bottom: 8px;
  min-height: 30px;
  padding-bottom: 10px;
  border-radius: 8px;
  opacity: 1;
  z-index: 10;
}

.Alert .notification::before {
  font-size: 24px;
  margin-top: -17px;
}

.Alert .notification-container {
  width: auto !important;
}

.Alert .notification-message .message {
  margin-right: 20px;
}

/* Success message */
.Alert .notification-success {
  background-color: #dff2e9;
  color: green;
}

.Alert .notification-success::before {
  content: "\263a";
}

/* Mobile version */
@media only screen and (max-width: 549px) {
  .Alert {
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
  }
  .notification-container {
    width: 100%;
    position: relative;
  }
}
