.Barra-Busqueda {
  max-width: 500px;
  width: 90%;
  float: left;
  height: 40px;
  margin-top: 15px;
  margin-left: 1rem;
}

.SearchContainer {
  display: flex;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.MuiAutocomplete-input:first-child {
  height: 30px;
  border-radius: 4px 0 0 4px !important;
  padding: 6px 12px !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.labelSearch {
  width: 100%;
}

.Barra-Busqueda i.fa-search {
  float: right;
  margin-top: 10px;
  font-size: 23px;
}

.Barra-Busqueda .InputNome {
  font-family: var(--titleFont);
}

.IconSearch {
  margin-left: 0 !important;
  border-radius: 0 4px 4px 0 !important;
  height: 42px;
}

@media only screen and (max-width: 992px) {
  .Barra-Busqueda {
    max-width: 300px;
  }
}

/* Pantallas pequenas */
@media only screen and (max-width: 768px) {
  .Barra-Busqueda {
    max-width: 80%;
  }

  .BarraSuperiorUsuario .Linguas {
    display: none;
  }

  .BarraSuperiorUsuario .Usuario {
    display: inline-block;
    float: none;
  }

  .SearchBarContainer {
    display: inline;
    width: 100vw;
  }

  .BarraBusquedaMobil .IconSearch ion-icon {
    color: var(--focusedButtonTextColor);
    margin-left: 0;
  }

  .BarraSuperior .AvatarMenu {
    margin-top: -50px;
  }

  .IconSearch {
    /*margin-top: -40px;*/
  }
  .IconSearhMobile {
    margin-top: 25px;
  }
}

.IconSearhMobile {
  display: inline-block;
  text-align: right;
  margin-right: 1rem;
  height: 100%;
  cursor: pointer;
}

.IconSearhMobile .IconSearch {
  margin: 0;
}

.IconSearhMobile ion-icon {
  font-size: 25px;
  cursor: pointer;
}

.BarraBusquedaMobil {
  top: 60px;
  width: 100vw;
}

.PecharBarraBusqueda {
  font-size: 25px;
  width: auto;
  height: 55px;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding-right: 1rem;
  padding-top: 0.8rem;
}
@media only screen and (max-width: 821px) {
  .BarraBusquedaMobil {
    top: 0;
    margin-left: 15px;
    width: 35vw;
  }
}

@media only screen and (max-width: 425px) {
  .IconSearhMobile .IconSearch {
    padding-top: 0;
    padding-left: 0;
  }
  .SearchBarWrapper {
    flex: none !important;
  }
  .IconSearhMobile {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-top: 0px;
  }
  .PecharBarraBusqueda {
    padding-right: 0;
  }
  .BarraBusquedaMobil {
    top: 60px;
    width: 100%;
    left: 0;
    z-index: 5;
    position: absolute;
  }

  .Barra-Busqueda {
    width: 100%;
    left: 0;
  }
}
