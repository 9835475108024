.Breadcrumbs {
  text-align: left;
}

.Breadcrumbs * {
  display: inline-block;
}

.Breadcrumb {
  padding-left: 10px;
}

.Breadcrumb:after {
  content: ">";
  padding-left: 10px;
}

.Breadcrumb:first-child {
  padding-left: 0px;
}

.Breadcrumb:last-child:after {
  content: "";
}

.BreadcumbLink {
  font-weight: bold;
  cursor: pointer;
}
