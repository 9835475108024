@import url("./Resources/css/font-awesome.min.css");

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

* {
  scrollbar-width: 4px;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  border: 4px solid #ffffff80;
}

.oculto {
  display: none;
}

.App {
  text-align: center;
  font-family: var(--textFont);
  color: var(--textColor);
  background: var(--backgroundColor);
  background-image: var(--backgroundImage);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  width: 100%;
  --alertBackgroundColor: #ff3300;
}

.App-header {
  color: var(--appColor);
  padding-top: 20px;
  height: calc(20vh - 20px);
}

.App-container {
  padding-top: 20px;
}

.App-fullscreen {
  height: 100vh;
}

h1 {
  display: block;
  font-size: 2.5rem;
  padding-top: 5vh;
  padding-bottom: 5px;
  letter-spacing: 0;
  margin: 0;
  font-family: var(--titleFont);
  color: var(--titleColor);
}

h2 {
  display: block;
  font-size: 34px;
  padding-top: 10px;
  padding-bottom: 5px;
  letter-spacing: 0.25px;
  margin: 0;
  font-weight: 400 !important;
}

h3 {
  display: block;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 5px;
  letter-spacing: 0;
  margin: 0;
}

h4 {
  display: block;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  letter-spacing: 0.25px;
  font-weight: 500;
  margin: 0;
}

/* Enlaces e botons */
.App-link {
  color: var(--textColor) !important;
}

a {
  color: var(--textColor) !important;
  text-decoration: none !important;
}

a:hover {
  color: var(--hoverColor) !important;
}

a:focus {
  color: var(--focusColor) !important;
  outline: none;
  box-shadow: none;
}

input[type="button"]:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  background: var(--appColor) !important;
  border-color: var(--appColor) !important;
  cursor: pointer;
}

.btn-primary:hover {
  background: var(--hoverColor) !important;
  border-color: var(--hoverColor) !important;
}

.button-focused,
.button-default,
.button-default,
.button-secondary,
.button-link-primary,
.button-link-secondary {
  display: block;
  border: 1px solid transparent;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  width: calc(100% - 6px);
  margin-left: 3px;
  cursor: pointer;
  min-height: 40px;
  font-size: 16px;
  text-align: center;
  outline: none;
  box-shadow: none;
}

.App .button-icon {
  padding-left: 12px;
  padding-right: 12px;
  width: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 3px;
}

/* Boton default */
.button-default {
  background: var(--defaultButtonColor);
  color: var(--defaultButtonTextColor);
  border-color: var(--defaultButtonBorderColor);
}

/* Boton default */
.button-default-invert {
  background: var(--defaultButtonColor);
  color: var(--defaultButtonTextColor);
  border-color: var(--defaultButtonBorderColor);
}

.button-default:focus {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-default:hover {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-default:disabled {
  background: #ffffff !important;
  color: grey;
  border-color: grey !important;
}

a.button-default {
  color: var(--defaultButtonTextColor);
}

a.button-default:hover {
  color: var(--hoverButtonTextColor);
}

a.button-default:focus {
  color: var(--hoverButtonTextColor);
}

a.button-default:disabled {
  color: grey !important;
}

.button-default.Selected {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

/* Boton focused */
.button-focused {
  background-color: var(--focusedButtonColor) !important;
  color: var(--focusedButtonTextColor);
  border-color: var(--focusedButtonBorderColor) !important;
}

.button-focused ion-icon {
  color: var(--focusedButtonTextColor);
}

.button-focused:focus {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-focused:focus ion-icon {
  color: var(--hoverButtonTextColor);
}

.button-focused:hover {
  background-color: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-focused:hover ion-icon {
  color: var(--hoverButtonTextColor);
}

.button-focused:disabled {
  background: var(--hoverButtonColor);
  /*TODO disabled */
  color: var(--hoverButtonTextColor);
  /*TODO disabled */
  border-color: var(--hoverButtonBorderColor);
  /*TODO disabled */
}

a.button-focused {
  color: var(--focusedButtonTextColor) !important;
}

a.button-focused:hover {
  color: var(--hoverButtonTextColor) !important;
}

a.button-focused:focus {
  color: var(--hoverButtonTextColor) !important;
}

a.button-focused:disabled {
  color: #ffffff !important;
  /* TODO texto botón Primario disabled */
}

.button-focused.Selected {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

/* Boton secondary */
.button-secondary {
  background: var(--focusedButtonTextColor);
  color: var(--focusedButtonColor);
  border-color: var(--focusedButtonColor);
}

.button-secondary ion-icon {
  color: var(--focusedButtonColor);
}

.button-secondary:focus {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-secondary:focus ion-icon {
  color: var(--hoverButtonTextColor);
}

.button-secondary:hover {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.button-secondary:hover ion-icon {
  color: var(--hoverButtonTextColor);
}

.button-secondary:disabled {
  background: var(--hoverButtonColor);
  /*TODO disabled */
  color: var(--hoverButtonTextColor);
  /*TODO disabled */
  border-color: var(--hoverButtonBorderColor);
  /*TODO disabled */
}

a.button-secondary {
  color: var(--focusedButtonTextColor) !important;
}

a.button-secondary:hover {
  color: var(--hoverButtonTextColor) !important;
}

a.button-secondary:focus {
  color: var(--hoverButtonTextColor) !important;
}

a.button-secondary:disabled {
  color: #ffffff !important;
  /* TODO texto botón Primario disabled */
}

.button-secondary.Selected {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

/* Boton link primario */
.button-link-primary {
  background: transparent;
  color: var(--focusColor);
  border-color: transparent;
  font-weight: bold;
}

.button-link-primary:focus {
  background: transparent;
  /* TODO background botón link Primario focus */
  color: var(--appColor);
  /* TODO texto botón Primario link focus */
  border-color: transparent;
  /* TODO borde botón link Primario normal */
}

.button-link-primary:hover {
  background: transparent !important;
  /* TODO background botón link Primario hover */
  color: var(--hoverColor);
  /* TODO texto botón link Primario hover */
  border-color: transparent !important;
  /* TODO borde botón link Primario hover */
}

.button-link-primary:disabled {
  background: transparent !important;
  /* TODO background botón link Primario disabled */
  color: grey;
  /* TODO texto botón Primario link disabled */
  border-color: transparent !important;
  /* TODO borde botón link Primario disabled */
}

a.button-link-primary {
  color: var(--appColor) !important;
  /* TODO texto botón link Primario normal */
}

a.button-link-primary:hover {
  color: var(--hoverColor) !important;
  /* TODO texto botón link Primario hover */
}

a.button-link-primary:focus {
  color: var(--appColor) !important;
  /* TODO texto botón link Primario focus */
}

a.button-link-primary:disabled {
  color: grey !important;
  /* TODO texto botón link Primario disabled */
}

.button-link-primary.Selected {
  background-color: transparent;
  /* TODO texto botón Primario seleccionado */
  color: var(--appColor);
  /* TODO texto botón Primario seleccionado */
  border-color: transparent;
  /* TODO borde botón Primario seleccionado */
}

/* Boton link secundario */
.button-link-secondary {
  background: transparent;
  /* TODO background botón link Secundario normal */
  color: grey;
  /* TODO texto botón link Secundario normal */
  border-color: transparent;
  /* TODO borde botón link Secundario normal */
  font-weight: bold;
}

.button-link-secondary:focus {
  background: transparent;
  /* TODO background botón link Secundario focus */
  color: grey;
  /* TODO texto botón Secundario link focus */
  border-color: transparent;
  /* TODO borde botón link Secundario normal */
}

.button-link-secondary:hover {
  background: transparent !important;
  /* TODO background botón link Secundario hover */
  color: grey;
  /* TODO texto botón link Secundario hover */
  border-color: transparent !important;
  /* TODO borde botón link Secundario hover */
}

.button-link-secondary:disabled {
  background: transparent !important;
  /* TODO background botón link Secundario disabled */
  color: grey;
  /* TODO texto botón Secundario link disabled */
  border-color: transparent !important;
  /* TODO borde botón link Secundario disabled */
}

a.button-link-secondary {
  color: grey !important;
  /* TODO texto botón link Secundario normal */
}

a.button-link-secondary:hover {
  color: grey !important;
  /* TODO texto botón link Secundario hover */
}

a.button-link-secondary:focus {
  color: grey !important;
  /* TODO texto botón link Secundario focus */
}

a.button-link-secondary:disabled {
  color: grey !important;
  /* TODO texto botón link Secundario disabled */
}

.button-link-secondary.Selected {
  background-color: transparent;
  /* TODO texto botón Secundario seleccionado */
  color: grey;
  /* TODO texto botón Secundario seleccionado */
  border-color: transparent;
  /* TODO borde botón Secundario seleccionado */
}

/* Inputs */
input[type="text"],
input[type="password"] {
  padding: 8px;
  border: none;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 3px 0px #707070;
  margin-left: 0px;
  max-height: 40px;
  font-size: 16px;
  background: #f7f7f7;
  color: #7c7c7c;
}

input[type="comment"] {
  padding: 8px;
  border: none;
  border-radius: 5px;
  width: calc(100% - 70px);
  box-shadow: 0px 0px 3px 0px #707070;
  margin-left: 0px;
  max-height: 40px;
  font-size: 16px;
  background: #f7f7f7;
  color: #7c7c7c;
}

.react-datepicker-wrapper::before {
  width: 50px;
  height: 40px;
  position: relative;
  float: right;
  z-index: 9;
  margin-bottom: -40px;
  font-family: "FontAwesome";
  content: "\f073";
  background: #ebebeb;
  border-radius: 0px 5px 5px 0px;
  border-left: 2px solid #dfdfdf;
  padding-left: 17px;
  padding-top: 7px;
  pointer-events: none;
  font-size: 15px;
}

.react-datepicker-wrapper input {
  cursor: pointer;
}

/* Mensaxes */
.msg-erro {
  color: var(--alertBackgroundColor);
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Listado */
.Enumeracion .Linea {
  display: block;
}

.Enumeracion .Sublinea {
  display: block;
  padding-left: 20px;
}

/* Loader */
.loading {
  min-width: 100vw;
  min-height: 100vh;
  background: var(--backgroundColor);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.loader:empty {
  min-width: 100vw;
  min-height: 100vh;
  background: var(--backgroundColor);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.classModalApps {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  font-weight: bolder;
}

.classModalApps h5 {
  width: 100%;
  text-align: center !important;
  font-weight: normal;
  font-size: 1.8rem;
}

.classModalApps img {
  width: 70%;
}

.contentModalApps {
  margin: 2rem 0rem;
}
