.splash {
  min-width: 100vw;
  min-height: 100vh;
  background: url("~@resources/Images/splash.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.video-splash {
  min-width: 100vw;
  max-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
}

/* Pantallas pequentas */
@media only screen and (max-width: 768px) {
  .splash {
    min-width: 100vw;
    min-height: 100vh;
    background: url("~@resources/Images/splash movil.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.logo-splash {
  min-width: 100vw;
  min-height: 100vh;
  background: url("~@resources/Images/logoPetisgo.svg") no-repeat center center;
  background-size: min(80%, 500px);
}
