
.Playlist {
    padding: 0 3%;
}

.Playlist .Titulo {
    padding: 20px 30px 0px 5px;
    text-align: left;
    font-weight: bold;
    font-size: 2rem;
    color: var(--bannerWidgetTitleColor);
}

.Playlist .DetailTitulo {
    text-align: left;
    font-weight: bold;
    padding-left: 5rem;
    padding-top: 20px;
    font-size: 2rem;
    color: var(--bannerWidgetTitleColor);
}

.PlaylistCollection {
    max-width: 96%;
    white-space: nowrap;
    position: static;
    margin: 0;
}

.DetailPlaylistCollection {
    white-space: nowrap;
    position: static;
    overflow: hidden;
    margin: 0;
}

.PlaylistItem {
    padding: 8px 8px;
    height: 100%;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.PlaylistHighlightItem {
    height: 100%;
    background-size: cover;
}

.PlaylistHighlightItemByTenant {
    height: 100%;
    background-size: cover;
}

.PlaylistHighlight > a{
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.PlaylistTextItem {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: initial;
    border-radius: 30px 30px 10px 10px;
    position: relative;
}

.PlaylistTextItemImage {
    width: auto;
    border-radius: 14px 14px 0px 0px;
    -o-object-fit: cover;
    object-fit: cover;
}

.PlaylistCircularItem {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: initial;
    border-radius: 200px;
    position: relative;
}

.PlaylistCircularItemImage {
    width: auto;
    border-radius: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}

.PlaylistCollection > ul > li {
    /*padding-left: 8px;
    padding-right: 8px;*/
}

.DetailPlaylistCollection > ul > li {
  /*  padding-left: 8px;
    padding-right: 8px; */
}

.PlaylistItemContainer {
    position: relative;
    transition: transform 300ms ease-out, border-radius 600ms ease-out;
}

.PlaylistTextItemContainer {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform 300ms ease-out, border-radius 600ms ease-out;
    width: 250px;
}
.linkSpace {
 flex-grow: 1;
}
.linkSpace > .boton-play {
    max-width: 50%;
}

.CardContainer .Alpha img,  .DetailCardContainer .Alpha img{
    opacity: 0.5;
}

.CardContainer .Alpha .PlaylistItem {
    background-color: var(--backgroundColor);
    border-radius: var(--bannerWidgetBorderRadius, 15px);
    padding: 0;
    margin: 8px;
}

.PlaylistCircularItemContainer {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform 300ms ease-out, border-radius 600ms ease-out;
}

.react-multi-carousel-item {
    /*padding: 5px;*/
}

.PlaylistItemImage {
    width: 100%;
    border-radius: var(--bannerWidgetBorderRadius, 15px);
    -o-object-fit: cover;
    object-fit: cover;
}


.PlaylistItemTitle {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    color: var(--bannerWidgetTextColor);
    font-family: var(--rowTextFont);
    text-align: left;
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.PlaylistItemSubtitlePerRating {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 2.5px;
    margin-bottom: -3px;
    color: var(--bannerWidgetTextColor);
    font-family: var(--rowTextFont);
    text-align: left;
    width: 50%;
}

.PlaylistTextItemTitleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.PlaylistTextItemTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--bannerWidgetTextColor);
    font-family: var(--rowTextFont);
    text-align: left;
    font-weight: bold;
    margin-bottom: 0px !important;
    margin-right: 12px;
}


.PlaylistTextItemText {
    color: var(--bannerWidgetTextColor);
    font-family: var(--rowTextFont);
    text-align: left;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}

.PlaylistTextItemTextHour {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0;
    color: var(--bannerWidgetTextColor);
}

.PlayListTextItemTimeContainer {
    margin-top: 6px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.PlaylistTextItemTimeIcon {
    height: 12px;
    width: 12px;
}


.PlaylistCircularItemTitle {
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    font-family: var(--rowTextFont);
    text-align: left;
    z-index: 1;
}

.Live .PlaylistItemTitle {
    position: absolute;
    width: 80%;
    bottom: 80px;
    color: var(--focusedButtonTextColor);
    font-size: 1rem;
    font-weight: bold;
    padding-left: 20px;
    margin-top: 0;
    text-shadow: black 0.1em 0.1em 0.2em;
    text-transform: uppercase;
    text-overflow: unset;
    white-space: normal;
}
.PlaylistContainer{
    position: relative;
}

.CarouselContainer {
    position: relative;
    min-height: 20rem;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
.Principal {
    /*min-height: 28rem;*/
}

.SpinnerContainer{
    position: absolute;
    min-height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    align-items: center;
}

.Live .PlaylistItemSubtitle {
    position: absolute;
    bottom: 55px;
    padding: 0 10px 0 20px;
    font-size: 0.8rem;
    color: var(--focusedButtonTextColor);
    text-shadow: black 0.1em 0.1em 0.2em;
}

.GroupWidget .PlaylistItemTitle {
    text-align: left;
    padding: 1rem;
    white-space: break-spaces;
    position: absolute;
    max-width: 100%;
    bottom: 10px;
    color: var(--bannerWidgetTextColor);
}

.PlaylistItemSubtitle {
    color: #888;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlaylistItemSubLabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 5px;
    font-family: var(--titleFont);
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    height: 16px;
}

.VideoCategory, .VideoTag {
    text-align: left;
    width: max-content;
}

.Live .VideoCategory {
    position: absolute;
    top: 15px;
    right: 0;
    padding-right: 20px;
}

.VideoCategory .Subtag, .VideoTag .Subtag {
    padding-left: 8px;
    padding-right: 8px;
    height: 18px;
    border-radius: 5px;
}

.VideoCategory ion-icon, .VideoTag ion-icon {
    display: inline-block;
    height: 16px;
}

.PlaylistItem .VideoCategory {
    margin-top: -25px;
    margin-left: 5px;
}
.PlaylistItem .HighlightTagsContainer .VideoCategory{
    margin-top: 0px;
}

.PlaylistItemSubLabel3 {
    --Tagbackgroundcolor3: var(--tagBackgroundColor, #DAEDFF);
    --Tagtextcolor3: var(--tagTextColor, #0A84FF);
    color: var(--Tagtextcolor3);
    background: var(--Tagbackgroundcolor3)
}

.PlaylistItemSubLabelV {
    --TagbackgroundcolorV: var(--tagBackgroundColor, #E8F6EE);
    --TagtextcolorV: var(--tagTextColor, #65C28F);
    background: var(--TagbackgroundcolorV);
    color: var(--TagtextcolorV);
}
.VideoType {
    position: absolute;
    top: 15px;
    right: 15px;
}

.VideoLiveTag {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #ac0505;
    color: white;
    padding: 4px 6px;
    font-size: 0.8rem;
    font-weight: bold;
}

.VideoScheduledTag {
    background-color: white !important;
    color: black !important;
}

.liveSportDesign {
    padding-left: 15px;
}
.liveSportDesign > .PlaylistItemTitle {
    font-weight: bold !important;
}

.PlaylistItemSubLabelL {
    --TagbackgroundcolorL: var(--tagBackgroundColor, #FF9D00);
    --TagtextcolorL: var(--tagTextColor, #24282E);
    color: var(--TagtextcolorL);
    background: var(--TagbackgroundcolorL)
}

.PlaylistItemSubLabelF {
    --TagbackgroundcolorF: var(--tagBackgroundColor, #FFF0D9);
    --TagtextcolorF: var(--tagTextColor, #FF9D00);
    color: var(--TagtextcolorF);
    background: var(--TagbackgroundcolorF)
}

.PlaylistItemSubLabelSerial {
    --TagbackgroundcolorF: var(--tagBackgroundColor, #FFF0D9);
    --TagtextcolorF: var(--tagTextColor, #FF9D00);
    color: var(--TagtextcolorF);
    background: var(--TagbackgroundcolorF)
}

.PlaylistItemSubLabelAR {
    --TagbackgroundcolorF: var(--tagBackgroundColor, #FFF0D9);
    --TagtextcolorF: var(--tagTextColor, #FF9D00);
    color: var(--TagtextcolorF);
    background: var(--TagbackgroundcolorF)
}

.PlaylistItemSubLabelTag {
    --TagbackgroundcolorF: var(--tagBackgroundColor, #FFFFFF);
    --TagtextcolorF: var(--tagTextColor, #128605);
    color: var(--TagtextcolorF);
    background: var(--TagbackgroundcolorF);
    font-size: 12px;
}

/* Anterior e seguinte carrusel */
.Playlist .react-multiple-carousel__arrow {
    display: none;
    min-width: 0;
    min-height: 0;
    width: 25px;
    height: 60px;
    cursor: pointer;
    background: "#000000a0";
}

.Playlist:hover .react-multiple-carousel__arrow {
    display: inline-block;
}

.Playlist .react-multiple-carousel__arrow--left::before {
    font-size: 18px;
    width: 8px;
    font-weight: bold;
}

.Playlist:hover .react-multiple-carousel__arrow--left {
    display: inline-block;
}

.Playlist .react-multiple-carousel__arrow--left {
    left: 0;
    border-radius: 0 15px 15px 0;
}

.Playlist .react-multiple-carousel__arrow--right::before {
    font-size: 18px;
    width: 10px;
    font-weight: bold;
}

.Playlist .react-multiple-carousel__arrow--right {
    right: 0;
    border-radius: 15px 0 0 15px;
}

.progress .progress-bar {
    background-color: var(--focusColor);
    opacity: 1;
}

.BlockCarousel > ul {
    margin: 0px;
    padding: 0px;
    /*transform: translate3d(0px, 0px, 0px) !important;*/
}


.BarProgress.progress {
    height: 0.7rem;
    width: 100%;
    position: relative;
    background: rgba(92, 90, 90, 0.5);
    border-bottom-left-radius: var(--bannerWidgetBorderRadius);
    border-bottom-right-radius: var(--bannerWidgetBorderRadius);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


.PlaylistHighlight img {
    max-width: 100%;
    min-width: auto;
    height: auto !important;
}

.PlaylistHighlightBig img {
    max-width: 100%;
    min-width: auto;
    height: auto !important;
}

.ImgHeight {
    height: 500px;
}


.TitleHighlight {
    font-family: var(--titleFont);
    color: var(--featuredWidgetTitleColor);
    font-size: 1.8rem;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
}

.TitleHighlight:hover {
    text-decoration: underline;
}

.DescriptionHighlight {
    text-align: justify;
    font-family: var(--textFont);
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.DescHighlight {
    font-family: var(--rowTextFont);
    color: var(--featuredWidgetTextColor);
    font-size: 16px;
    text-align: justify;
    line-height: 1.5em;
    overflow: hidden;
    width: 100%;
    height: 4.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.TextHome {
    width: 100%;
    padding-left: 3%;
    /*padding-right: 30px;*/
    display: flex;
    flex-direction: column;
    color: var(--textColor) !important;
}

.ButtonAccess {
    width: 100%;
    display: inline-block;
    text-align: right;
    padding-top: 40px;
}

.Live .ButtonAccess {
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: left;
    padding-left: 15px;
    margin-top: 0;
}

.ButtonAccess .Access {
    width: 150px;
    font-family: var(--rowTextFont);
    display: inline-block;
}

.Live .ButtonAccess .Access {
    min-width: 120px;
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.Live .button-focused {
    padding-top: 6px;
    padding-bottom: 6px;
}

ol.carousel-indicators {
    justify-content: flex-end !important;
    margin-right: 5% !important;
    margin-left: 50%;
}

ol.carousel-indicators li {
    max-width: 15px;
    height: 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: var(--textColor);
    opacity: 1;
}

.carousel-indicators .active {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    opacity: 1;
    background-color: var(--focusColor);
}

.OverContainerHome {
    width: 100%;
    height: 35%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding-bottom: 1rem;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.HighlightRatingContainer {
    width: 20rem;
    display: flex;
    justify-content: center;
}

.HighlightRating {
    width: 60%;
}

.HighlightTagsContainer {
    display: flex;
    gap: 1rem;
    justify-content: left;
}

.HighlightInfoContainer {
    display: none;
    margin-top: 1rem;
    /*display: flex;*/
    font-size: small;
}

.HighlightInfoContainer > *:after {
    content: " - "
}

.HighlightInfoContainer > *:last-child:after {
    content: ""
}

.HighlightInfoContainer .Xenero {
    text-transform: capitalize;
}

.HighlightInfoContainer:hover {
    color: var(--textColor) !important;
}

.Poster .PlaylistItemFooter {
    bottom:15px
}

.Poster .VideoType {
    top: 30px;
    rigth: 30px;
}

.POSTER .VideoType {
    top: 30px;
    rigth: 30px;
}
.POSTER .PlaylistItemFooter {
    bottom:0px
}

.imageDefault {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}




@media only screen and (max-width: 1280px) {
    .CarouselContainer {
        min-height: auto;
    }
    .ImgHeight {
        min-width: auto !important;
    }
    .OverContainerHome {
        height: 45%;
    }
}

/* Pantallas pequentas */
@media only screen and (max-width: 768px) {



    .Playlist:hover .react-multiple-carousel__arrow--left, .Playlist .react-multiple-carousel__arrow--left,
    .Playlist:hover .react-multiple-carousel__arrow--right, .Playlist .react-multiple-carousel__arrow--right {
        display: block;
    }

    .PlaylistCollection {
        position: static;
        margin-right: 5px;
        margin-left: 5px;
    }

    .DescHighlight {
        display: none;
    }

    .ButtonAccess {
        display: none;
    }

    .PlaylistHighlight {
        min-height: auto !important;
    }

    .TitleHighlight {
        font-size: 1.5rem;
    }

    .ImgHeight {
        height: auto;
        min-width: auto !important;
    }
    .OverContainerHome {
        height: 60%;
    }
}

@media only screen and (max-width: 491px) {
    .PlaylistItemImage {
        width: 100%;
    }
    .SpinnerContainer {
        justify-content: center !important;
    }
}

@media only screen and (max-width: 425px) {
    .TitleHighlight {
        font-size: larger;
        text-decoration: underline;
    }

    .PlaylistHighlightItemByTenant .OverContainerHome {
        display: none;
    }

    .PlaylistHighlightItemByTenant .PlaylistHighlight img{
        min-width: auto !important;
        max-height: none !important;
    }

    .PlaylistItemContainer {
        height: auto;
    }

    .PlaylistCircularItemContainer {
        width: 120px;
        height: auto;
    }

    .PlaylistItemImage {
        height: auto;
    }

    .PlaylistCircularItemImage {
        width: 120px;
        height: auto;
    }

    .CardContainer:hover, .DetailCardContainer:hover {
        transform: scale(1) !important;
        -webkit-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
}
    .Live .PlaylistItemTitle {
        font-size: 10px;
        overflow: hidden;
        width: 120px;
    }
    .Live .PlaylistItemSubtitle {
        font-size: 10px;
        overflow: hidden;
        width: 120px;
    }

    .VideoCategory, .VideoTag {
        opacity: 0;
    }
    .HighlightTagsContainer {
        display: none;
    }

    .HighlightInfoContainer {
        display: none;
    }

    .CarouselContainer, .Principal {
        min-height: auto;
    }
    .Playlist .Titulo {
        font-size: 1.5rem;
    }
}


.CardContainer {
    /*transition: transform 300ms ease-out, border-radius 600ms ease-out;*/
    z-index: -1;
}

.DetailCardContainer {
    /*transition: transform 300ms ease-out, border-radius 600ms ease-out;*/
    z-index: -1;
    margin-right: 1rem;
}

.GroupWidget {
  /*  padding-bottom: 8%;*/
}

.PlaylistItemFooter {
    position: absolute;
    opacity: 0;
    background: linear-gradient(0deg, rgba(24, 24, 24, 0.9) 0%, rgba(24, 24, 24, 0.4) 80%, rgba(24, 24, 24, 0.3) 90%);
    bottom: 0;
    border-radius: var(--bannerWidgetBorderRadius, 15px);
    width: 96%;
    margin-left: 2%;
    margin-left: 2%;
    margin-bottom: 2%;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
}

.PlaylistTextItemFooter {
    background: var(--appColor);
    padding: 10px 14px;
    border-radius: 0 0 4px 4px;
    height: 100px;
}

.PlaylistCircularItemFooter {
    position: absolute;
    opacity: 0;
    background: linear-gradient(0deg, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.3) 10%, rgba(24, 24, 24, 0.8) 50%,
    rgba(24, 24, 24, 0.3) 80%, rgba(24, 24, 24, 0) 85%);
    bottom: 30%;
    height: 40%;
    left: 0;
    padding-left: 5%;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
}

.PlaylistItemGenre {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: white;
    margin: 2.5px 0 0 5px;
}
.ListIcons {
    display: flex;
    margin: 0px 0 8px 0;
    justify-content: flex-start;
    position: relative;
}



.CardContainer:hover, .DetailCardContainer:hover {
    position: absolute;
    z-index: 1;
}
.CardContainer:hover{
    transform: scale(1.03, 1.03);
    transition-duration: 0.2s;
}
.CardContainer-text{
    padding: 5px;
}
.CardContainer:hover .FirstItem, .DetailCardContainer:hover .FirstItem {
    /*transform: scale(1.03, 1.03);*/
    transition-duration: 0.2s;
}

.CardContainer:hover .LastItem, .DetailCardContainer:hover .LastItem {
    /*transform: scale(1.03, 1.03);*/
    transition-duration: 0.2s;
}

.CardContainer:hover .PlaylistItemImage, .DetailCardContainer:hover .PlaylistItemImage {
    border-radius: var(--bannerWidgetBorderRadius, 15px);
}

.CardContainer:hover .PlaylistItemFooter, .DetailCardContainer:hover .PlaylistItemFooter {
    transition-duration: 1.5s;
    opacity: 1;
}

.CardContainer:hover .PlaylistCircularItemFooter {
    transition-duration: 1.5s;
    opacity: 1;
}

.GroupWidget .CardContainer:hover .PlaylistItemImage {
    opacity: 1;
}

.HoverRating {
    width: 100px !important;
}

.HoverRatingScore {
background: linear-gradient(90deg, rgba(255, 157, 10, 0) 7px, rgb(255, 157, 10) 8px);
border-radius: 3px 5px 5px 3px;
color: white;
display: inline-block;
height: 18px;
line-height: 20px;
padding: 0 5px 0 12px;
position: relative;
margin: 4px 10px 6px 0;
text-decoration: none;
}

.HoverRatingScore::before {
background: rgba(255, 255, 255, 0);
border-bottom: 9px solid rgb(255, 157, 10);
border-left: 8px solid transparent;
border-top: 9px solid rgb(255, 157, 10);
content: '';
position: absolute;
left: 0;
top: 0;
}

.ShowMoreText {
margin-right: -200px;
opacity: 0;
transition-duration: 0.8s;
}

.ShowMoreText:hover {
margin-right: 0;
opacity: 1;
}

.iconTVG {
position: absolute;
height: 32px;
width: 32px !important;
left: 15px;
top: 15px;
}

.iconTVGCircular {
position: absolute;
height: 32px;
width: 32px;
left: 55px;
margin-top: 15px;
}

.IconForked {
position: absolute;
right: 15px;
top: 15px;
}

