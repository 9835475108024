.PasswordModalBackground {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PasswordModal {
    background: var(--backgroundColor);
    color: var(--textColor);
    border-radius: var(--bannerWidgetBorderRadius);
    z-index: 9;
    padding: 40px 60px 20px 60px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 8px 3px #3c3c3d;
}

.PasswordTitle {
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.PasswordBlock {
    margin-bottom: 2rem;
}

.PasswordText {
    font-size: 1rem;
}

.PasswordContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;
    text-align: left;
}

.PasswordInput {
    background-color: transparent !important;
    border: 2px solid var(--appColor) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: var(--textColor);
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    width: 92% !important;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
}

.PasswordButton {
    width: 200px !important;
}

.DisabledButton {
    background-color: grey !important;
    border: 2px solid grey !important;
    padding: 0.2rem 0.5rem !important;
    font-size: 0.9rem !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    color: white;
    cursor: auto;
}