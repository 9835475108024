.BotonVer.button-focused {
    float: left;
    margin-left: 0px !important;
    padding: 6px 4px 0px 4px;
    width: 100%;
}



.SecondaryButton {
    border: 1px solid var(--textColor) !important;
    background-color: transparent !important;
}

.SecondaryButton:hover {
    border: 1px solid var(--hoverButtonBorderColor) !important;
    background: var(--hoverButtonBorderColor) !important;
}

.NonAtopado {
    height: 150px;
    padding-top: 70px;
    font-size: x-large;
}

/* Información xeral */
.FichaImage {
    width: 100%;
    object-fit: cover;
}

.FichaButtons {
    margin-left: 15px;
}

.VistaPrevia {
    height: 100vh;
    max-height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.FichaOverContainer {
    width: 100%;
    /*min-height: 230px;*/
    position: relative;
    color: white;
    text-align: left;
    padding: 2rem 3% !important;
    background-repeat: no-repeat;
}

.FichaTitle {
    font-size: xx-large;
    font-family: var(--titleFont);
    color: white;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ContentAuthor {
    font-size: 1rem;
}

div.DescricionContainer {
    padding-left: 25px;
    margin-right: -20px;
}

.FichaDescricion {
    margin-bottom: 10px;
    font-family: var(--textFont);
    text-align: justify;
    line-height: 1.5em;
    overflow: hidden;
    height: 4.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.InfoPrevia {
    font-size: small;
    margin-top: 5px;
    width: 100%;
}

.InfoPrevia > *:after {
    content: " - "
}

.InfoPrevia > *:last-child:after {
    content: ""
}

.InfoPrevia .Xenero {
    text-transform: capitalize;
}

.VotesCategoriesContainer {
    max-width: 100%;
}

.VotesCategoriesContainer .Rating {
    display: inline-block;
    /*width: 240px;*/
    padding-right: 20px;
}

.VotesCategoriesContainer .VideoCategory, .VotesCategoriesContainer .VideoTag {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-bottom: 5px;
}

.VotesCategoriesContainer .VideoCategory .Subtag, .VotesCategoriesContainer .VideoTag .Subtag {
    height: 28px;
    padding-top: 4px;
}

.PlayFavContainer {
    max-width: 100%;
    display: flex;
    margin-bottom: 8px;
    flex-wrap: wrap;
}

.PlayFavContainer .boton-play {
    min-width: 200px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button-back a {
    font-size: 0.8rem;
    display: inline-block;
    text-decoration: underline !important;
}

.PlayFavContainer .Favorite {
    width: 50px;
    display: inline-block;
}

.PlayFavContainer .Share {
    width: 50px;
    display: inline-block;
}

.share-container {
    justify-content: center;
    height: 100%;
}

.share-icon {
    width: 15px;
    filter: invert(1);
    margin-bottom: 6px;
    margin-right: 2px;
}

.PlayFavContainer .BotonEngadirFav.button-default, .PlayFavContainer .BotonQuitarFav.button-default {
    padding: 0;
    font-size: 27px;
    margin: 0;
    width: 40px;
    height: 40px;
}

.ReportContentContainer {
    padding-top: 2px;
}

/* Reparto */
.RepartoTitle {
    text-transform: uppercase;
    padding-bottom: 5px;
    color: var(--textColor);
}

.RepartoContent {
    max-height: 75px;
    overflow: auto;
}

.RepartoContent > *:after {
    content: ", "
}

.RepartoContent > *:last-child:after {
    content: ""
}

.playlistContainer {
    margin-bottom: 2rem;
}

.VideoDetailPage .FrameworkHome {
    margin-top: 0px;
}

.VideoDetailPage .Titulo {
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: x-large !important;
    text-transform: uppercase;
    font-family: var(--titleFont);
}

.VideoDetailPage {
    padding-bottom: 40px;

}

.principalImage {
    position: relative;
    width: 100%;
    min-height: 150px;
}
.principalImage > img{
    z-index: 1;
    max-width: 100%;
    min-width: auto !important;
}
.contentPrincipalImage {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
}

.contentPrincipalImage .FichaOverContainer {
    padding-left: 3% !important;
}


.VideoDetailPage .VotesCategoriesContainer {
    display: flex;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType {
    position: relative;
    top:1px;
    right: 0;
    margin-right: 5px;
}
.VideoDetailPage .VotesCategoriesContainer {
    display: flex;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType {
    position: relative;
    top:1px;
    right: 0;
    margin-right: 5px;

}

.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon {
    height: 28px;
}
.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon img{
    height: 100%;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon img{
    max-height: 28px;
}

.FichaTecnica {
    text-align: left;
    margin-left: 10px;
    padding-top: 10px;
}

.textDetailContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 6rem;
}

.TextTitle {
    font-family: var(--titleFont);
    margin-top: 1.3rem;
    text-align: left;
}

.TextTitle:first-child {
    font-family: var(--titleFont);
    margin-top: 2.6rem;
}

.SubText {
    font-family: var(--textFont);
}

.FichaCasting {
    margin-top: 5rem;
}

.FichaInfoContainer {
    margin-top: 3rem;
    width: 100%;
}

.Framework {
    background: var(--detailPageBgImage);
    background-color: var(--detailPageBgColor);
    width: 100%;
    height: 220px;
    position: absolute;
    left: 0;
    text-align: left;
}

.SpinnerVideoDetail {
    width: 100%;
    position: relative;
    text-align: center;
    float: left;
    margin-top: 20%;
}

.SeeMore {
    width: 100px;
    height: 25px;
    float: right;
    text-align: center;
    margin-right: 10px;
}

.SeeMore:hover {
    background: var(--hoverButtonColor);
    color: var(--hoverButtonTextColor);
    border-color: var(--hoverButtonBorderColor);
    border-radius: 5px;
    width: 100px;

}

.contentContainer {
    padding: 2rem 3% !important;
    min-height: 20rem;
}

.DescriptionTitle {
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 6rem;
    font-size: x-large;
}

.DescriptionDetail {
    text-align: justify;
    font-family: var(--textFont);
    font-size: 16px;
    margin-bottom: 2rem;
    margin-right: 6rem;
    white-space: pre-line; 
}

.CommentsContainer {
    max-width: 700px;
    padding-bottom: 50px;
}

.FichaInfoDetail {
    margin-left: 20px;
    margin-right: -20px;
}

.alignLeft {
    text-align: left;
}

.buttonDisabled {
    opacity: 0.4;
}

@media (max-width: 992px) {
    .textDetailContainer {
        margin-left: 0;
    }

    .DescriptionDetail {
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .VotesCategoriesContainer {
        margin-bottom: 1rem;
    }
    .contentContainer {
        min-height: auto;
    }
    .FichaOverContainer {
        padding: 0rem 1rem !important;
    }
    .principalImage {
        display: flex;
        flex-direction: column;
        min-height: 35vh;
    }
    .contentContainer {
        padding: 0rem 3% !important;
        min-height: 20rem;
    }
}

@media only screen and (max-width: 491px) {
    .VotesCategoriesContainer .VideoCategory, .VotesCategoriesContainer .VideoTag {
        display: none;
    }
    .VotesCategoriesContainer .Rating {
        padding-right: 0px;
    }
    .boton-play > .button-focused {
        width: 100%;
        margin-left: 0px;
    }
    .VistaPrevia {
        background-size: 100%;
        background-position: top;
        background-repeat: no-repeat;
        height: 57vh;
    }
    .VistaPreviaWithoutRating {
        height: 50vh;
    }
    .FichaTitle {
        white-space: wrap;
        font-size: large;
    }
    .PlayFavContainer {
        justify-content: space-between;
    }
    .PlayFavContainer .boton-play {
        margin-right: 0px;
    }
    .PlayFavContainer .Share, .PlayFavContainer .Favorite, .PlayFavContainer .boton-play {
        margin-left: 0px;
        margin-right: 0px;
        width: auto;
    }
    .PlayFavContainer .button-default {
        margin-left: 0px;
    }
}