.Header {
    height: 150px;
    background-color: var(--catalogueHeadBackgroundColor);
}

.Catalogo {
    width: 100%;
}

.Catalogo .VideoCategory {
    display: flex;
    align-items: center;
}

.Discover {
    margin-left: 3%;
    font-size: 1.7rem;
    color: var(--textColor);
    width: 97%;
    text-align: left;
    font-family: var(--titleFont);
    padding-top: 35px;
    padding-bottom: 15px;
}

.SelectsContainer {
    margin-left: 3%;
    display: flex;
    margin-bottom: 2rem;
    gap: 1rem 2rem;
    flex-wrap: wrap;
}

.Select {
    color: var(--textColor) !important;
}

.MuiSelect-select.MuiSelect-select {
    padding-right: 0 !important;
}

.MuiSelect-icon {
    position: relative !important;
    color: var(--textColor) !important;
    margin: 0 !important;
    top: 0 !important;
    right: 0 !important;
}

.InputCatalogue {
    width: 100%;
    font-family: var(--titleFont);
    display: flex;
    justify-content: flex-start;
    padding-left: 3%;
    margin-left: 0 !important;
}

.OrderMenuContainer {
    text-align: left;
    width: 100%;
    padding-left: 0 !important;
}


.InputSearchCatalogue {
    position: relative;
    margin-top: 25px;
}

.InputCatalogue2 {
    width: 370px;
    padding-left: 40px !important;
    margin-left: -30px !important;
}


.IconSearchCatalogue {
    padding-top: 10px;
    position: relative;
    width: 30px;
    display: inline-block;
    text-align: center;
    z-index: 9;
}

.CatalogueVideo {
    display: inline-block;
    text-align: left;
    width: 100%;
}

.CatalogueVideo .PlaylistItemContainer {
    overflow: visible;
    margin: 1rem 1rem 1rem 0 !important;
}

.CatalogueVideo :first-child {
    margin-left: 0 !important;
}

.Catalogo .PlaylistItemContainer:hover .contentFooter {
    transition-duration: 1.5s;
    opacity: 1;
}

.PlaylistItemButtonContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.8rem 0;
}

.PlayButton.button-focused {
    float: left;
    margin-left: 0px !important;
    width: 100px;
}

.Catalogo .PlaylistItemContainer:hover {
    transform: scale(1.03, 1.03);
    transition-duration: 0.2s;
    z-index: 1;
}

.Catalogo .infinite-scroll-component {
    text-align: left;
    padding-top: 30px;
    padding-left: 3%;
    padding-right: auto;
}

.PlaylistItemImageSearch {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.PlaylistItemCatalogue {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.VideoContainer {
    cursor: pointer;
}

.SpinnerCatalogue {
    width: 100%;
    position: relative;
    text-align: center;
    float: left;
}

.NoResultsSearch {
    text-align: center;
    width: 100% !important;
}

.ImageNoResult {
    width: 30%;
}

.NoResults {
    font-family: var(--filterTitleColor);
}

.OrderMenu {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 5px;
    width: 230px;
    display: inline-block;
}

.MenuCatalogueContainer {
    color: var(--filterTitleColor);
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    gap: 0 1rem;
}

.MenuCatalogueContainer ion-icon {
    color: var(--filterTitleColor) !important;
}

.sectionsMenuTitle {
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 150px;
}

.orderMenuTitle{
    font-size: 1.2rem;
}

.MenuDesplegableOrder {
    position: absolute;
    z-index: 9;
    background: var(--backgroundColor);
    font-size: medium;
    border-radius: 15px;
    box-shadow: 0px 1px 3px 0px #9b9b9b;
    padding: 5px;
    padding-top: 12px;
}

.MenuDesplegableOrder .MenuSubsectionOrder {
    display: block;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 5px;
    min-width: 170px;
}

.MenuDesplegableOrder .MenuSubsectionOrder:hover, .MenuDesplegableOrder .MenuSubsectionOrder.Selected {
    color: var(--textColor) !important;
    border-left: 3px solid var(--appColor);
    background-color: var(--hoverColor);
}

.MenuDesplegableOrder .MenuSubsectionOrder:hover:after, .MenuDesplegableOrder .MenuSubsectionOrder.Selected:after {
    content: "";
    opacity: 0.2;
    display: block;
    height: 42px;
    width: calc(100% - 8px);
    position: absolute;
    margin-top: -32px;
    margin-left: -20px;
}

.Catalogo .Breadcrumbs {
    padding-bottom: 15px;
}

.NoChildrenContainer {
    width: calc(100vw - 103px);
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.NoChildrenContainer p {
    font-size: 1.5rem;
}

/* Pantallas pequentas */
@media only screen and (max-width: 549px) {
    .Catalogo .infinite-scroll-component {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 0;
    }

    .Catalogo .InputCatalogue .InputCatalogue2 {
        width: 100%;
        position: relative;
    }

    .Catalogo .OrderMenu {
        padding-right: 0;
    }

    .Catalogo .Buttons {
        overflow: auto;
        white-space: nowrap;
    }

    .Catalogo .Buttons a {
        display: inline-block;
    }
    .CatalogoVideo .PlaylistItemContainer {
        margin-top: 0 !important;
    }
    .Catalogo .PlaylistItemContainer {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0 !important;
    }
}