.onBoardingContainer {
  background-color: var(--backgroundColor);
  color: var(--textColor);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-size: cover;
  background-position: center;
}

.Background-Image {
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 8rem;
  margin-top: 2rem;
}

.onBoardingLogo {
  width: 220px;
  height: 100px;
  background: var(--logo) no-repeat center center;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.carouselContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 1rem;
}

.carouselItem {
  /*height: 620px;*/
  z-index: 1;
}

.carouselImage {
  max-width: 30%;
  /*  margin: 3rem 0; */
}
.carouselImage img {
  width: 100%;
}

#carousel .carousel-indicators {
  position: relative !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem;
}

#carousel .carousel-indicators li {
  max-width: 10px !important;
  height: 10px !important;
  background-color: var(--textColor) !important;
}

#carousel .carousel-indicators .active {
  max-width: 15px !important;
  height: 15px !important;
  background-color: var(--focusColor) !important;
}

.carouselTextContainer {
  padding: 0 10%;
}

.slideTitle {
  color: var(--textColor) !important;
  font-weight: bold;
  font-size: 2rem;
}

.slideText {
  color: var(--textColor) !important;
  font-size: 1.2rem;
}

.buttonContainer {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.buttonRow {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.BackButton {
  width: 200px !important;
  background-color: transparent !important;
  border: 1px solid var(--textColor) !important;
  color: var(--textColor) !important;
}

.BackButton:hover {
  border: 2px solid var(--hoverButtonBorderColor) !important;
  background: var(--hoverButtonColor) !important;
  color: var(--textColor) !important;
}

.BackButton:disabled {
  border: 2px solid lightgray !important;
  background: lightgray !important;
  color: var(--textColor);
  cursor: auto;
}

.NextButton {
  width: 200px !important;
  border: 2px solid var(--focusedButtonBorderColor) !important;
  background-color: var(--focusedButtonColor) !important;
}

.NextButton:hover {
  border: 2px solid var(--hoverButtonBorderColor) !important;
  background-color: var(--hoverButtonColor) !important;
}

.SkipButton {
  border: none !important;
  color: var(--textColor);
  cursor: pointer;
}

.BackgroundImageLeft {
  position: absolute;
  bottom: 0;
  left: 0;
}

.BackgroundImageRight {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 541px) {
  .carouselItem {
    height: auto !important;
    margin-bottom: 1rem;
  }

  .carouselImage {
    width: 40%;
  }
}

@media (max-width: 414px) {
  .carouselContainer {
    margin: 1rem 0;
    justify-content: center;
    grid-gap: 0rem;
    gap: 0rem;
  }

  .carouselItem {
    height: auto !important;
    margin-bottom: 1rem;
  }

  .carouselImage {
    width: 60%;
    margin: 1rem 0;
  }

  .slideTitle {
    font-size: 1.2rem;
  }

  .slideText {
    font-size: 0.9rem;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .BackButton {
    width: 120px !important;
  }

  .NextButton {
    width: 120px !important;
  }
}
