.Menu .BarraSuperiorUsuario {
  position: absolute;
  left: 0px;
  padding-left: 80px;
  padding-right: 20px;
}

.Menu .MenuLateral {
  position: fixed;
  top: 0;
  width: 60px;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  background-color: var(--appColor);
  z-index: 23;
  box-shadow: 4px 0px 8px 3px rgba(0, 0, 0, 0.2);
}

.MenuContainer {
  height: calc(100% - 30px);
  max-height: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 30px;
}

.WithMenuContainer.Nofooter {
  padding-bottom: 0;
}

.WithMenuContainer {
  position: relative;
  margin-top: 70px;
  padding-left: 80px;
  min-height: calc(100vh - 220px);
  padding-bottom: 40px;
  z-index: 1;
}

.MenuSection {
  height: 60px;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
}

.MenuSection:hover,
.MenuSectionRes:hover {
  cursor: pointer;
}

.MenuSection img {
  max-height: 50px;
  margin-top: -15px;
}

.MenuSection.active {
  color: #8f8f8f;
  cursor: default;
  background-color: var(--focusColor);
}

.MenuSection i {
  font-size: 24px;
}

.MenuSection i.fa-search,
.MenuSection i.fa-bars {
  font-size: 18px;
}

.MenuSection .SectionText {
  font-size: 12px;
  display: block;
  margin-top: -6px;
  color: red;
}

ion-icon {
  color: var(--hoverButtonTextColor);
  /* TODO Cor de texto de boton primario*/
  pointer-events: none;
}

.LogoutIcon {
  color: var(--alertBackgroundColor) !important;
}

.settings-sharp > svg {
  max-height: 40px !important;
  width: auto;
  margin: 0;
}

.exit-sharp > svg {
  max-height: 40px !important;
  width: auto;
  margin: 0;
}

.MenuLateralRes .exit-sharp > svg,
.MenuLateralRes .settings-sharp > svg {
  padding-top: 0px !important;
  margin-top: 13px !important;
  min-width: 40px;
}

.MenuLateralRes .exit-sharp > .TextRes,
.MenuLateralRes .settings-sharp > .TextRes,
.MenuLateralRes .color-palette-sharp > .TextRes {
  margin-left: 30px !important;
}

#LogoutIcon {
  filter: invert(79%) sepia(64%) saturate(4480%) hue-rotate(357deg) brightness(103%) contrast(105%);
}

.Menu .MenuLateralRes {
  position: fixed;
  z-index: 23;
  background-color: var(--backgroundColor);
  color: var(--hoverButtonTextColor);
  opacity: 1;
  height: 100%;
  padding: 0 0.25rem 0rem 0.75rem;
}

.NotificationMenuRes {
  position: fixed;
  left: 80px;
  z-index: 21;
  width: 80%;
  max-width: 400px;
  background-color: var(--backgroundColor);
  color: var(--hoverButtonTextColor);
  opacity: 1;
  height: 100%;
  overflow-y: auto;
}

.Menu .MenuLateralRes ion-icon {
  color: var(--hoverButtonTextColor);
  float: left;
  font-size: 24px;
}

.Menu .MenuLateralRes .Logo-Barra {
  margin-left: 40px !important;
}

.Menu .MenuLateralRes .MenuSection {
  cursor: default;
}

.Menu .MenuLateralResContainer {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: var(--backgroundColor);
}

.MenuSectionRes {
  height: 60px;
  background-color: var(--backgroundColor);
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0.35rem;
}

.MenuSectionRes img {
  max-height: 50px;
}

.cinfo-deputacion img {
  max-width: 35px;
  margin-top: 0px;
}

.MenuLateralRes .cinfo-deputacion img {
  margin-left: -5px;
}

.TextRes {
  color: var(--hoverButtonTextColor);
  font-size: 14px;
  margin-left: 8px;
}

.cinfo-celta .TextRes {
  margin-left: 25px;
}

.PecharMenu {
  cursor: pointer;
  padding: 0 0.35rem;
  width: 100%;
  text-align: right;
}

.LogoMenu {
  float: left;
  margin-top: -20px;
  margin-left: -5px;
}

.MenuSectionRes.active {
  cursor: default;
  background-color: var(--focusColor) !important;
  color: var(--hoverButtonTextColor) !important;
}

.MenuSectionRes.active ion-icon,
.MenuSectionRes.active .TextRes {
  color: var(--hoverButtonTextColor) !important;
}

.NotificationsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.1rem;
  margin: 0 0.5rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 30px;
}

.Signal {
  background-color: var(--alertBackgroundColor) !important;
}

.NotificationsNumber {
  margin: 0 !important;
  color: white !important;
}

.NotificationMenuTitle {
  text-align: left;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 1.5rem;
  color: var(--textColor);
}

.NotificationMenuSubtitle {
  text-align: left;
  padding-left: 30px;
  font-size: 0.9rem;
  color: var(--textColor);
  margin: 0 !important;
}

.NotificationToggleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/*ACTIVATE NOTIFICATION SWITCH*/
.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--textColor) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--textColor) !important;
}

.AlertContainer {
  margin-top: 3rem;
}

.AlertCard {
  margin: 1rem 1.4rem;
  height: 120px;
  background-color: var(--footerBackgroundColor) !important;
  display: flex;
  border-radius: 8px;
  position: relative;
}

.AlertCard .deleteIconMessage {
  top: 2%;
}

.AlertCard .deleteIconMessage .Icono-trophy {
  filter: brightness(0) invert(1);
}

.NewCard {
  background-color: var(--hoverButtonColor) !important;
  cursor: pointer;
}

.AlertImage {
  height: 120px;
  width: auto;
  border-radius: 8px 0 0 8px;
}

.AlertTextContainer {
  text-align: left;
  padding: 0.5rem 1rem;
}

.AlertTitle {
  margin-bottom: 0.2rem !important;
}

.AlertText {
  font-size: 0.8rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0 !important;
}

.NotificationsLateralContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background-color: var(--alertBackgroundColor);
  margin: 1.5rem;
  border-radius: 16px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.NotificationsLateralNumber {
  margin: 0 !important;
  font-size: 1.2rem;
}

/* Pantallas pequenas */
@media only screen and (max-width: 768px) {
  .Menu .BarraSuperiorUsuario {
    width: 100%;
    padding-left: 40px;
    left: 0px;
    text-align: right;
  }

  .WithMenuContainer {
    padding-left: 0px;
    width: 100%;
    left: 0;
    min-height: calc(100vh - 230px);
  }

  .Footer {
    width: 100% !important;
    left: 0 !important;
  }

  .Menu .MenuLateral {
    height: 60px;
    width: 60px;
    background-color: var(--backgroundColor);
    color: var(--hoverButtonTextColor);
    box-shadow: none;
    position: absolute;
  }

  .Menu ion-icon {
    color: var(--hoverButtonTextColor);
    font-size: 24px;
  }
}

@media only screen and (max-width: 414px) {
  .NotificationMenuRes {
    position: fixed;
    width: 100%;
    left: 0;
    top: 70px;
  }
}

@media only screen and (max-width: 491px) {
  .WithMenuContainer {
    padding: 0 5px;
  }

  .Menu .BarraSuperiorUsuario {
    padding: 0;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
}
