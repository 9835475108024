.BarraSuperior {
  height: 70px;
  margin-bottom: 5px;
  width: 100%;
  background: var(--backgroundColor);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.LogoContainer {
  cursor: pointer;
}

.Logo-Barra {
  width: 130px;
  float: left;
  height: 60px;
  margin-top: 5px;
  background: var(--logo) no-repeat center center;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.Register .Logo-Barra {
  margin-left: 0px;
}

.groupOptions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.SearchBarWrapper {
  flex: 1;
}

.languages {
  float: right;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.languages .MenuDesplegable {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 60px;
  background: var(--backgroundColor);
  font-size: medium;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 #9b9b9b;
  padding: 12px 5px 5px;
}
.displayNoneTooltip {
  display: none !important;
}

.LanguageMenuIconContainer {
  display: flex;
}

#LanguageMenuIcon {
  width: 30px !important;
  height: 30px !important;
  color: var(--titleColor) !important;
}

.BarraSuperior .Button-Language {
  display: inline;
  cursor: pointer;
  border-right: 2px solid var(--textColor);
  padding-right: 15px;
  padding-left: 15px;
}

.BarraSuperior .Button-Language:last-child {
  border: none;
}

.BarraSuperior .Button-Language.Selected {
  font-weight: bold;
  cursor: default;
  color: var(--focusColor);
}

.BarraSuperior .Button-Live {
  float: right;
  padding-right: min(5%, 25px);
  padding-top: 1rem;
  margin-left: 1rem;
  position: relative;
  cursor: pointer;
}

.BarraSuperior .Button-Live .iconLiveChild {
  filter: invert(100%);
}

.BarraSuperior .Button-Live .iconLiveShadow {
  filter: invert(50%);
}

.BarraSuperior .Usuario {
  float: right;
  margin-right: 20px;
  z-index: 0;
}

.BarraSuperior .UserSeccion {
  display: flex;
  align-items: center;
}

.BarraSuperior .MenuDesplegableContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.BarraSuperior .UserSeccion .MenuDesplegable {
  position: absolute;
  z-index: 9;
  right: 3px;
  background: var(--backgroundColor);
  font-size: medium;
  border-radius: 15px;
  box-shadow: 0px 1px 3px 0px #9b9b9b;
  padding: 5px;
  padding-top: 12px;
}

.MenuDesplegable .MenuSubsection {
  display: block;
  text-align: left;
  padding: 8px 20px 10px;
  min-width: 250px;
}

.MenuDesplegable .MenuSubsection:hover,
.MenuDesplegable .MenuSubsection.Selected {
  color: var(--textColor) !important;
  border-left: 3px solid var(--appColor);
  background-color: var(--hoverColor);
}

.MenuDesplegable .MenuSubsection:hover:after,
.MenuDesplegable .MenuSubsection.Selected:after {
  content: "";
  opacity: 0.2;
  display: block;
  height: 42px;
  width: calc(100% - 8px);
  position: absolute;
  margin-top: -32px;
  margin-left: -20px;
}

.BarraSuperior .AvatarMenu {
  position: relative;
  z-index: 2;
  height: 30px;
  border: 3px solid white;
  border-radius: 50px;
}

.LoginButton {
  color: var(--textColor) !important;
  cursor: pointer;
}

.BarraSuperiorUsuario .Usuario {
  float: right;
}

.RankingMenu {
  position: relative;
  z-index: 1;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  background: white;
  border-radius: 12px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: -14px;
  max-height: 25px;
}

.RankingMenu .TrophyMenu {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: 10px;
}

.RankingMenu .PointsMenu {
  font-size: 16px;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 491px) {
  .LogoContainer {
    margin-left: 55px;
  }
  .groupOptions {
    padding: 0 5px;
  }
}
@media only screen and (min-width: 492px) {
  .BarraSuperiorUsuario .Usuario {
    display: flex;
    align-items: center;
  }
  .LogoContainer {
    display: block;
    padding-left: 3%;
  }
}

@media only screen and (max-width: 769px) {
  .RankingMenu {
    margin-top: -50px;
  }

  .BarraSuperior .UserSeccion {
    padding-top: 50px;
  }
  .RankingMenu {
    margin-top: -50px !important;
    margin-left: 2px !important;
  }
  .languages .MenuDesplegable {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 50px;
    background: var(--backgroundColor);
    font-size: medium;
    border-radius: 15px;
    box-shadow: 0px 1px 3px 0px #9b9b9b;
    padding: 12px 5px 5px;
  }
  .LoginButton {
    height: 70px;
    display: table-cell;
    color: var(--textColor) !important;
    vertical-align: middle;
    cursor: pointer;
  }
  .LoginButton ion-icon {
    font-size: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .BarraSuperior .Usuario {
    margin-right: 5px;
    float: right !important;
  }

  .BarraSuperiorUsuario .Logo-Barra {
    margin-left: 10px;
  }

  .BarraSuperior .UserSeccion {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 650px) {
  .Usuario .UserSeccion {
    display: none;
  }

  .RankingMenu {
    margin-top: -100px;
    z-index: 3;
    margin-left: 0;
  }
}
