.ReportContent {
    color: #b3b3b3;
    display: inline-block;
}

.ReportContent:hover {
    color: var(--alertBackgroundColor);
}

.ReportContentButton {
    cursor: pointer;
}

.ReportIcon {
    font-size: 22px;
    padding-right: 5px;
}

.ReportText {
    font-size: 13px;
}

.ModalBackground {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReportContentOptions {
    background: var(--backgroundColor);
    color: var(--textColor);
    border-radius: var(--bannerWidgetBorderRadius);
    z-index: 9;
    padding: 20px 0;
    width: 400px;
    text-align: left;
}

.ReportContentSentMessage {
    padding: 1rem 2rem;
    text-align: center;
}

.SentMessageContainer {
    margin-bottom: 2rem;
}

.ReportOptionContainer {
    margin-bottom: 20px;
}

.ReportOption {
    padding: 8px 20px;
    margin-top: 1px;
    width: 100%;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.ReportOption:hover {
    background: var(--focusedButtonColor);
    color: var(--focusedButtonTextColor);
}

.container {
    max-height: 25px !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark-label {
    margin-left: 2rem;
}

.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid var(--textColor);
}

.container:hover input ~ .checkmark {
    background-color: transparent;
}

.container input:checked ~ .checkmark {
    background-color: transparent;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: var(--textColor);
}

.ReportOption:last-child {
    box-shadow: none;
}

form.ReportOption, form.ReportOption:hover {
    cursor: default;
    background: var(--backgroundColor);
    color: var(--textColor);
}

.ReportOptionTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ReportOptionInput {
    margin: 0 20px 10px !important;
    width: auto !important;
    background-color: transparent !important;
    border: 1px solid dimgray !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: var(--textColor);
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}

.ReportOptionButtonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
}

.ReportOptionButton {
    border: 1px solid var(--textColor) !important;
    background-color: transparent !important;
    font-size: 0.9rem !important;
}

.ReportOptionButton:hover {
    border: 2px solid var(--hoverButtonBorderColor) !important;
    background: var(--hoverButtonBorderColor) !important;
}

.ReportTitle {
    font-weight: 500;
    margin: 0 20px 10px;
    color: var(--alertBackgroundColor);
    font-size: 1.2rem;
}

.ReportSubtitle {
    font-weight: 900;
    margin: 0 20px 10px;
    font-size: 0.9rem;
}

.textDetailCotainer {
    display: flex;
    padding: 32px;
    gap: 100px;
}

.textBlock {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.textTitle {
    font-weight: bold;
}

.textInfo {
    font-size: 16px;
}

@media only screen and (max-width: 425px) {
    .ReportContentOptions {
        width: 100%;
        left: 0;
    }
}